<template>
  <v-container class="fill-heigt pt-0" fluid>
    <v-row class="d-flex flex-row py-1">
      <div class="text-left subtitle px-3" style="margin-top:2%">
        Biblioteca de Testes
      </div>
      <v-spacer></v-spacer>
    <v-col cols="3" md="2" class="text-right px-0 mt-1">
        <v-btn icon color="primary" @click="infoDialog = true">
          <v-icon>info</v-icon>
        </v-btn>
        <test-filter @apply="applyTestFilter" @clean="applyTestFilter" />
      </v-col>
      <v-col cols="9" md="4">
        <div class="text-right">
          <v-text-field
            v-model="textSearch"
            append-icon="mdi-magnify"
            label="Pesquisar pelo titulo"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-divider class="my-3" style="margin-top:-7%"></v-divider>
   <v-row class="py-0 px-3">
      <v-col cols="12" md="9"><v-row class="d-flex flex-row py-4">
        
          <v-radio-group v-model="filter.type" class="mt-0" row @change="applyFilter()">
            <v-radio label="Técnico" value="technician"></v-radio>
            <v-radio label="Psicológico" value="psychological"></v-radio>
          </v-radio-group>
           <v-checkbox
            v-model="filter.user"
            label="Meus Testes"
            :value="currentUser.id"
            class="mt-0"
            @change="applyFilter()"
          ></v-checkbox>
       
          <!-- <v-checkbox
            v-model="filter.type"
            label="Técnico"
            hide-details
            class="mt-0"
            value="technician"
            @change="applyFilter()"
          ></v-checkbox>

          <v-checkbox
            v-model="filter.type"
            label="Psicológico"
            hide-details
            class="mt-0 px-1"
            value="psychological"
            @change="applyFilter()"
          ></v-checkbox>

          <v-checkbox
            v-model="filter.user"
            label="Meus Testes"
            hide-details
            class="mt-0 px-1"
            :value="currentUser.id"
            @change="applyFilter()"
          ></v-checkbox> -->
          </v-row> 
      </v-col>
      <v-col cols="12" md="3" class="text-right justify-end">
        <div class="d-flex justify-end">
         
          <v-col cols="12" md="3" class="text-right justify-end">
        <div class="d-flex justify-end">
          <!-- <v-btn
            dense
            text
            class="mr-2"
            style="background: #FFF4E4; color:#FAA638;"
          >
            <v-icon left color="#FAA638">mdi-send</v-icon>Enviar Teste
          </v-btn> -->
          <v-btn dense color="primary" @click.prevent="dialog = !dialog">
            <v-icon left>mdi-plus</v-icon>Criar Novo
          </v-btn>
        </div>
      </v-col>
        </div>

      </v-col>
      
    </v-row>

    <infinite-scroll :items="filteredItems" :totalPages="totalPages" @refetch="fetch">
      <template v-slot:item="{ item }">
        <v-skeleton-loader
          v-if="$apollo.loading"
          elevation="2"
          v-bind="filteredItems"
          type="image, article"
        ></v-skeleton-loader>
        <test-card v-else :test="item" @delete="openConfirmeDialog" @edit="edit" @preview="showPreviewDialog" />
      </template>
    </infinite-scroll>

    <add-test-dialog :dialog="dialog" :test="test" @close="closeAddDialog()" />
    <preview-test-dialog :dialog="previewDialog" :test="test" @close="previewDialog = false" />
    <confirm-dialog
      :dialog="confirm"
      :id="removeId"
      question="Pretende remover o Teste?"
      @yes="deleteTest"
      @no="confirm = false"
    />
    <error-dialog
      :dialog="showError"
      :message="error"
      @close="showError = false"
    />
    <progress-dialog :processing="isLoading" />
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />
  </v-container>
</template>

<script>
import { DELETE_TEST_MUTATION } from './../graphql/Mutation.resolver'
import { GET_TESTS_ROOT_QUERY } from './../graphql/Query.resolver'
import { mapActions, mapGetters } from 'vuex'
import { formatError } from "@/utils";
import PreviewTestDialog from './../components/PreviewTestDialog.vue'
import TestCard from './../components/TestCard.vue'
import TestFilter from './../components/TestFilter.vue'
import AddTestDialog from '../components/AddTestDialog.vue'
import Test from '@/models/Test'
import InfiniteScroll from './../components/InfiniteScroll.vue'
import replaceSpecialCharsMixins from '@/mixins/replaceSpecialChars'
import ConfirmDialog from "./../../../components/ConfirmDialog.vue";
import ErrorDialog from "./../../../components/ErrorDialog.vue";
import ProgressDialog from "./../../../components/ProgressDialog.vue";
import SuccessDialog from "./../../../components/SuccessDialog.vue";
import dialogMixins from "@/mixins/dialog";
export default {
  name: 'Test',
  components: { 
    TestFilter, 
    TestCard, 
    PreviewTestDialog, 
    AddTestDialog, 
    InfiniteScroll,
    ConfirmDialog,
    ErrorDialog,
    ProgressDialog,
    SuccessDialog,
  },
  mixins: [ dialogMixins, replaceSpecialCharsMixins ],
  data: () => ({
    appliedFilter: true,
    dialog: false,
    dialogCandidate: false,
    filter: {
      areas: undefined,
      category: "",
      entity: undefined,
      limit: 8,
      page: 1,
      partner: undefined,
      positions: undefined,
      private: undefined,
      type: ""
    },
    infoDialog: false,
    infoView: {
      icon: 'mdi-clipboard-list-outline',
      title: 'Testes',
      content: ' De acordo com os requisitos da vaga e perfis dos candidatos, adquira ou crie testes técnicos e psicotécnicos.'
    },
    previewDialog: false,
    tests: [],
    test: new Test(),
    textSearch: undefined,
    totalPages: 1
  }),
  apollo: {
    tests: {
      query: GET_TESTS_ROOT_QUERY,
      variables () {
        return { filterTest: this.filter }
      },
      fetchPolicy: 'no-cache'
    }
  },
  watch: {
    tests: function (val) {
      if (this.appliedFilter) {
        this.setTestsState(val.tests)
        this.appliedFilter = false
      } else {
        this.setTestsScrolled(val.tests)
      }
      this.totalPages = val.totalPages
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
      getTests: 'test/getTests'
    }),
    filteredItems () {
      let resultItems = [...this.getTests]

      if (this.textSearch) {
        const text = this.replaceSpecialChars(this.textSearch)
        resultItems = resultItems.filter(t => 
          this.replaceSpecialChars(t.title).includes(text)
        )
      }

      return resultItems;
    }
  },
  methods: {
    ...mapActions({
      removeTestState: 'test/removeTest',
      setTestsState: 'test/setTests',
      setTestsScrolled: 'test/setTestsScrolled'
    }),
    async applyFilter () {
      this.appliedFilter = true
      this.fetch(1)
    },
    applyTestFilter (filter) {
      this.filter = { ...filter }
      this.applyFilter()
    },
    cleanFilter () {
      this.appliedFilter = true
      this.filter = {
        areas: undefined,
        category: undefined,
        limit: 4,
        page: 1,
        positions: undefined,
      }
    },
    closeAddDialog () {
      this.test = new Test()
      this.dialog = false;
      this.show = false;
    },
    async deleteTest(id) {
      this.confirm = false;
      this.removeId = undefined;
      this.isLoading = true;
      try {
        await this.$apollo.mutate({
          mutation: DELETE_TEST_MUTATION,
          variables: { id },
        });
        this.removeTestState(id);
        this.success = "Teste removido com sucesso!";
        this.showSuccess = true;
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
    edit (test) {
      this.test = test
      this.dialog = true
    },
    async fetch (page) {
      if (page > this.totalPages) { return }
      this.filter.page = page
      await this.$apollo.queries.tests.refetch()
    },
    openConfirmeDialog (id) {
      this.removeId = id
      this.confirm = true
    },
    search () {

    },
    showPreviewDialog (test) {
      this.test = test
      this.previewDialog = true
    }
  }
}
</script>