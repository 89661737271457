<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="750"
  >
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-stepper
        v-model="e6"
        vertical
      >
        <v-stepper-step
          :complete="e6 > 1"
          step="1"
        >
          Detalhes do Teste
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-card flat>
            <v-row class="pt-1">
              <v-col cols="12" md="10">
                <v-text-field
                  tile
                  title
                  v-model="test.title"
                  label="Título do teste"
                  dense
                  outlined
                  :rules="requiredRules"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="test.duraction"
                  label="Duração"
                  v-mask="'##:##'"
                  hint="00:00"
                  placeholder="00:00"
                  dense
                  outlined
                  tile
                  autocomplete="off"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="test.category"
                  :items="categories"
                  item-text="text"
                  item-value="key"
                  label="Categoria"
                  dense
                  outlined
                  :rules="requiredRules"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="test.type"
                  :items="types"
                  item-text="name"
                  item-value="val"
                  label="Tipo de teste"
                  dense
                  outlined
                  :rules="requiredRules"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="test.difficultyLevel"
                  :items="difficultyLevels"
                  item-text="name"
                  item-value="val"
                  label="Nível de dificuldade"
                  dense
                  outlined
                  :rules="requiredRules"
                  hide-details
                ></v-select>
              </v-col>
              <!-- <v-col cols="12" md="2">
                <v-currency-field
                  label="Preço"
                  outlined
                  dense
                  v-model="test.price"
                  @input="verifyPrice"
                  hide-details
                />
              </v-col> -->
              <v-col cols="12" md="6">
                <v-autocomplete
                  v-model="test.areas"
                  :items="getTrainingAreas($i18n.locale)"
                  item-text="text"
                  item-value="key"
                  label="Áreas de aplicabilidade"
                  dense
                  chips
                  small-chips
                  outlined
                  multiple
                  :rules="requiredRules"
                  hide-details
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @click="toggle"
                    >
                      <v-list-item-action>
                        <v-icon v-if="test.areas" :color="test.areas.length > 0 ? 'primary' : ''">
                          {{ icon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Selecionar todas
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  v-model="test.positions"
                  :items="getPositions($i18n.locale)"
                  item-text="text"
                  item-value="key"
                  label="Cargos"
                  dense
                  chips
                  small-chips
                  outlined
                  multiple
                  :rules="requiredRules"
                  hide-details
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @click="toggle2"
                    >
                      <v-list-item-action>
                        <v-icon v-if="test.positions" :color="test.positions.length > 0 ? 'primary' : ''">
                          {{ icon2 }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Selecionar todos
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="8">
                <v-textarea
                  v-model="test.description"
                  rows="6"
                  outlined
                  name="descricao"
                  label="Descrição do teste"
                  :rules="requiredRules"
                  hide-details
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="4">
                <croppa
                  v-model="croppa"
                  :width="180"
                  :height="180"
                >
                  <img
                    slot="placeholder"
                    :src="test.image ? `${apiUrl}/images/test/${test.image}` : `/android-chrome-512x512.png`"
                  >
                </croppa>
              </v-col>
            </v-row>
          </v-card>
          <v-row class="d-flex flex-row justify-end align-center mt-8 mb-1 mx-3">
            <v-btn
              text
              @click="$emit('close')"
            >cancelar</v-btn>
            <v-btn
              color="primary"
              @click="validate"
            >Continuar</v-btn>
          </v-row>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 2" step="2">
          Questões &amp; Respostas
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-card flat>
            <v-row class="pt-1" v-if="test.questions && test.questions[pos]">
              <v-col
                cols="12"
                md="12"
                class="text-center justfy-center"
              >
                Pontuação Remanescente <span class="primary--text">{{totalPercentage}}% - 100%</span>
              </v-col>
              <v-col
                cols="12"
                md="12"
                class="py-0 text-center justfy-center"
                v-if="test.questions"
              >
                <template v-for="(c, j) in test.questions">
                  <croppa
                    :key="j"
                    v-show="j == pos"
                    v-model="test.questions[j].croppa"
                    :width="300"
                    :height="180"
                    :initial-image="test.questions[j].picture ? `${apiUrl}/images/test/${test.questions[j].picture}` : ``"
                    @file-choose="handleCroppaQuestionImage(j)"
                    @image-remove="handleImageQuestionRemove(j)"
                  >
                    <img
                      slot="placeholder"
                      :src="test.questions[j].picture ? `${apiUrl}/images/test/${test.questions[j].picture}` : `/android-chrome-512x512.png`"
                    >
                  </croppa>
                </template>
              </v-col>
              <v-col
                v-if="test.type === 'psychological'"
                cols="12"
                class="py-2 text-center justify-center"
              >
                <v-btn-toggle
                  v-model="typeQuestion"
                  borderless
                >
                  <v-btn value="multipleChoice">
                    <span class="hidden-sm-and-down">Multipla Escolha</span>

                    <v-icon right>
                      mdi-order-bool-ascending-variant
                    </v-icon>
                  </v-btn>

                  <v-btn value="openResponse">
                    <span class="hidden-sm-and-down">Resposta Aberta</span>

                    <v-icon right>
                      mdi-forum
                    </v-icon>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col
                v-if="test.questions"
                cols="12"
                md="12"
              >
                <v-row class="py-0">
                  <v-col
                    v-if="test.questions"
                    cols="12"
                    md="10"
                  >
                    <v-text-field
                      v-show="isMultipleChoice"
                      tile
                      title
                      v-model="test.questions[pos].question"
                      label="Questão"
                      dense
                      outlined
                      hide-details
                    ></v-text-field>
                    <v-textarea
                      v-show="!isMultipleChoice"
                      v-model="test.questions[pos].question"
                      rows="3"
                      outlined
                      label="Questão"
                      hide-details
                    ></v-textarea>
                  </v-col>
                  <v-col
                    v-if="test.questions"
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      height="5"
                      tile
                      title
                      v-model.number="test.questions[pos].percentage"
                      label="Pontuação"
                      dense
                      outlined
                      type="number"
                      max="100"
                      min="0"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <template v-for="(alternative, index) in test.questions[pos].alternatives">
                <v-col
                  v-show="isMultipleChoice"
                  cols="12"
                  md="12"
                  class="pb-0"
                  :key="index"
                >
                  <v-row no-gutters>
                    <v-col cols="10" md="10">
                      <v-text-field
                        v-model="alternative.answer"
                        :label="`Alternativa ${index + 1}`"
                        dense
                        outlined
                        tile
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="1"
                      md="1"
                      class="text-end justify-end"
                    >
                      <v-switch
                        v-model="alternative.value"
                        :error="alternative.value === false ? true : false"
                        :success="alternative.value ? true : false"
                        :value="true"
                        class="mt-1 ml-6 justify-end"
                        inset
                        hide-details
                      ></v-switch>
                    </v-col>
                    <v-col
                      cols="1"
                      md="1"
                      class="text-end py-0 mt-1"
                    >
                      <v-btn
                        v-if="index === 0"
                        x-small
                        outlined
                        fab
                        color="secondary"
                        @click="addAlternative"
                      >
                        <v-icon small>mdi-plus</v-icon>
                      </v-btn>
                      <v-btn
                        v-else
                        small
                        icon
                        fab
                        color="secondary"
                        class="pb-2"
                        @click.stop="confirmeDelete({id: index, remove: 2})"
                      >
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </template>
              <v-col cols="12" md="12" >
                <v-text-field
                  tile
                  title
                  v-model="test.questions[pos].objective"
                  label="O que se pretende avaliar na questão acima"
                  dense
                  outlined
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" >
                <v-textarea
                  v-model="test.questions[pos].implication"
                  rows="3"
                  outlined
                  label="Qual é a resposta indicada, a sua implicação e da s restantes respostas"
                  hide-details
                ></v-textarea>
              </v-col>
              <v-col cols="12" class="text-right">
                <v-spacer></v-spacer>
                <v-btn
                  small
                  icon
                  fab
                  color="error"
                  class="pb-2"
                  @click.stop="confirmeDelete({id: pos, remove: 1})"
                >
                  <v-icon>mdi-trash-can-outline</v-icon>
                  <!-- Willdlay01 -->
                </v-btn>
              </v-col>
              <v-col
                v-if="test.questions"
                cols="12"
                md="12"
              >
                <v-pagination
                  v-model="position"
                  class="mb-4"
                  :length="test.questions.length"
                  @click.native="removedImagePreview"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card>
          <v-row no-gutters>
            <v-col cols="6">
              <v-btn
                v-show="totalPercentageAdded"
                :disabled="!totalPercentageAdded"
                block
                color="primary"
                @click="test.id ? update() : save()"
              >Fechar & Salvar</v-btn>
              <v-btn
                v-show="!totalPercentageAdded"
                :disabled="totalPercentageAdded"
                block
                color="primary"
                outlined
                @click="close()"
              >Cancelar</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                :disabled="totalPercentageToomach"
                block
                color="primary"
                @click="addNewQuestion"
              >Adicionar Questão</v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper>
    </v-form>
    <confirm-dialog 
      :dialog="confirm" 
      :id="removeId" 
      question="Pretende remover a questão?" 
      @yes="removeItem" 
      @no="confirm = false" 
    />

    <error-dialog :dialog="showError" :message="error" @close="closeErrorDialog" />
    <progress-dialog :processing="isLoading" />
    <success-dialog :dialog="showSuccess" :message="success" @close="showSuccess = false" />
  </v-dialog>
</template>

<script>
import { API_URL } from '@/api'
import { CREATE_TEST_MUTATION, UPDATE_TEST_MUTATION } from './../graphql/Mutation.resolver'
import { mapActions, mapGetters } from 'vuex'
import { formatError } from '@/utils'
import Test from '@/models/Test'
import ConfirmDialog from './../../../components/ConfirmDialog.vue'
import ErrorDialog from './../../../components/ErrorDialog.vue'
import ProgressDialog from './../../../components/ProgressDialog.vue'
import SuccessDialog from '../../../components/SuccessDialog.vue'
import { ADD_DIALOG } from '@/mixins/dialog'
export default {
  name: 'AddTestDialog',
  components: { ConfirmDialog, ErrorDialog, ProgressDialog, SuccessDialog },
  props: {
    dialog: Boolean,
    show: Boolean,
    test: Object
  },
  mixins: [
    ADD_DIALOG
  ],
  data: () => ({
    apiUrl: API_URL,
    confirm: false,
    croppa: {},
    e6: 1,
    entities: [],
    newImagesQuestion: [],
    percentage: 0,
    position: 1,
    removeId: undefined,
    testLocal: new Test(),
    testAreas: [],
    testPositions: [],
    typeQuestion: "multipleChoice",
    valid: true,
  }),
  created () {
    this.setTestInit()
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
      getTrainingAreas: 'library/trainingAreas',
      getPositions: 'library/positions'
    }),
    categories () {
      return [
        { text: 'Compreensão Verbal', key: 'verbalUnderstanding' },
        { text: 'Habilidades', key: 'skills' },
        { text: 'Jogos', key: 'games' },
        { text: 'Julgamento Situacional', key: 'situationalJudgment' },
        { text: 'Pensamento Abstracto', key: 'abstractThinking' },
        { text: 'Personalidade', key: 'personality' },
        { text: 'Raciocínio Lógico', key: 'logicalReasoning' },
        { text: 'Simulação', key: 'simulation' },
      ]
    },
    requiredRules () {
      return [v => !!v || "Campo obrigatório"]
    },
    difficultyLevels () {
      return [
        { name: "Básico", val: "basic" },
        { name: "Intermédio", val: "intermediate" },
        { name: "Avançado", val: "advanced" }
      ]
    },
    icon () {
      if (this.likesAllArea) return 'mdi-close-box'
      if (this.likesSomeArea) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    icon2 () {
      if (this.likesAllPosition) return 'mdi-close-box'
      if (this.likesSomePosition) return 'mdi-close-box'
      // if (this.likesSomePosition) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    isMultipleChoice () {
      return this.typeQuestion === 'multipleChoice'
    },
    likesAllArea () {
      return this.test.areas.length === this.getTrainingAreas(this.$i18n.locale).length || this.test.areas[0] === "all"
    },
    likesAllPosition () {
      return this.test.positions.length === this.getPositions(this.$i18n.locale).length || this.test.positions[0] === "all"
    },
    likesSomeArea () {
      return this.test.areas.length > 0 && !this.likesAllArea
    },
    likesSomePosition () {
      return this.test.positions.length > 0 && !this.likesAllPosition
    },
    pos () {
      return this.position - 1;
    },
    totalPercentage () {
      return this.test.questions
        ? Math.round(((this.test.questions.reduce((total, value) => ({ 
            percentage: Number(total.percentage) + Number(value.percentage) 
          })).percentage)) * 100) / 100
        : Number(this.percentage)
    },
    totalPercentageAdded () {
      return this.totalPercentage - this.percentage === 100
    },
    totalPercentageToomach () {
      return this.totalPercentage >= 100
    },
    types () {
      return [
        { name: "Técnico", val: "technician" },
        { name: "Psicológico", val: "psychological" }
      ]
    },
  },
  methods: {
    ...mapActions({
      setTestState: 'test/setTest',
      updateTestState: 'test/updateTest'
    }),
    addAlternative () {
      this.test.questions[this.pos].alternatives.push({ answer: "", value: false })
    },
    addNewQuestion () {
      const lastQuestion = this.test.questions[this.test.questions.length - 1]
      if (lastQuestion.question === "" || lastQuestion.percentage === "" || lastQuestion.percentage === 0) {
        this.error = "Preecha os campos em branco"
        this.showError = true
      } else if ((this.isMultipleChoice && lastQuestion.alternatives.length < 2)) {
        this.error = "Deve ter mais de uma Alternativa!"
        this.showError = true
      } else {
        this.test.questions.push({
          question: "",
          objective: "",
          implication: "",
          picture: undefined,
          alternatives: [
            {
              answer: "",
              value: true
            }
          ],
          croppa: {},
          total_correct: undefined,
          percentage: 0
        })
        this.position = this.test.questions.length
      }
    },
    confirmeDelete ({id, remove}) {
      this.removeId = id+"";
      this.confirm = true;
      this.remove = remove
    },
    close () {
      this.e6 = 1
      this.position = 1
      this.croppa = {}
      this.test = this.testLocal
      this.$refs.form.reset()
      this.$emit('close')
    },
    closeErrorDialog (value) {
      this.showError = value;
    },
    handleCroppaQuestionImage (position) {
      this.newImagesQuestion.push(position);
    },
    handleImageQuestionRemove (position) {
      const index = this.newImagesQuestion.findIndex(i => i == position);
      if (index > -1) {
        this.newImagesQuestion.splice(index, 1);
        this.test.questions[position].picture = undefined;
      }
    },
    removeItem (id) {
      if (this.remove === 1) {
        this.removeQuestion(id)
      } else {
        this.removeAlternative(id)
      }
    },
    removeAlternative (index) {
      this.confirm = false;
      this.test.questions[this.pos].alternatives.splice(+index, 1);
    },
    removedImagePreview () {
      // this.typeQuestion = this.test.questions[this.pos].alternatives[0].answer ? 'multipleChoice' : 'openResponse';
    },
    removeQuestion () {
      this.confirm = false;
      this.test.questions.splice(this.pos, 1);
      this.position = this.pos == 0 ? 1 : this.pos
    },
    async save() {
      this.isLoading = true
      try {
        this.test.entity = this.currentUser.entity
        this.test.status = this.test.entity ? this.test.status = false : this.test.status = true
        this.test.areas = this.likesAllArea ? this.testAreas : this.test.areas
        this.test.positions = this.likesAllPosition ? this.testPositions : this.test.positions
        this.test.newImage = this.croppa.generateDataUrl('image/jpeg', 0.8);
        delete this.test.user
        this.setImages();
        this.setTotalCorrectToQuestions();
        const { data } = await this.$apollo.mutate({
          mutation: CREATE_TEST_MUTATION,
          variables: { testInput: this.test }
        })
        this.setTestState(data.createTest)
        this.success = 'Teste adicionado com sucesso.'
        this.$emit('close')
        this.showSuccess = true
      } catch (error) {
        this.error = formatError(error.message)
        this.showError = true
      } finally {
        this.isLoading = false
      }
    },
    setImages () {
      for (let index = 0; index < this.test.questions.length; index++) {
        if (this.test.questions[index].croppa && this.test.questions[index].croppa.hasImage()) {
          this.test.questions[index].newPicture = this.test.questions[index].croppa.generateDataUrl('image/jpeg', 0.8)
          delete this.test.questions[index].croppa
        } else if (this.test.questions[index].croppa) {
          this.test.questions[index].picture = undefined
          delete this.test.questions[index].croppa
        }
        
      }
    },
    setTestInit () {
      this.e6 = 1
      if (this.test.id) {
        this.testLocal = Object.assign({}, this.test)
        this.e6 = 1
        this.position = 1
        this.typeQuestion = this.test.questions[0].alternatives[0].answer ? 'multipleChoice' : 'openResponse'
        this.test.areas = this.likesAllArea ? ["all"] : this.test.areas
        this.test.positions = this.likesAllPosition ? ["all"] : this.test.positions
      } else {
        this.position = 1
        this.test.areas = []
        this.test.positions = []
        this.test.questions = [{
          question: "",
          objective: "",
          implication: "",
          croppa: {},
          picture: undefined,
          alternatives: [
            {
              answer: "",
              value: true
            }
          ],
          totalCorrect: undefined,
          percentage: 0
        }]
      }
    },
    setTotalCorrectToQuestions () {
      for (let index = 0; index < this.test.questions.length; index++) {
        let total_correct = 0;
        for (let j = 0; j < this.test.questions[index].alternatives.length; j++) {
          if (this.test.questions[index].alternatives[j].value) {
            total_correct = total_correct + 1;
          } else {
            this.test.questions[index].alternatives[j].value === false
          }
        }
        this.test.questions[index].totalCorrect = total_correct;
      }
    },
    toggle () {
      this.$nextTick(() => {
        if (this.likesAllArea) {
          this.testAreas = []
          this.test.areas = []
        } else {
          this.testAreas = this.getTrainingAreas(this.$i18n.locale).map(a => a.key)
          this.test.areas = ["all"]
        }
      })
    },
    toggle2 () {
      this.$nextTick(() => {
        if (this.likesAllPosition) {
          this.testPositions = []
          this.test.positions = []
        } else {
          this.testPositions = this.getPositions(this.$i18n.locale).map(p => p.key)
          this.test.positions = ["all"]
        }
      })
    },
    async update() {
      this.isLoading = true
      this.test.status = this.test.entity ? this.test.status = false : this.test.status = true
      this.test.areas = this.likesAllArea ? this.testAreas : this.test.areas
      this.test.positions = this.likesAllPosition ? this.testPositions : this.test.positions
      this.test.newImage = this.croppa.generateDataUrl('image/jpeg', 0.8);
      delete this.test.user
      this.setImages();
      this.setTotalCorrectToQuestions();
      try {
        if (this.test.entity) {
          this.test.status = false
        }
        const { data } = await this.$apollo.mutate({
          mutation: UPDATE_TEST_MUTATION,
          variables: { testInput: this.test }
        })
        this.updateTestState(data.updateTest)
        this.success = 'Teste actualizado com sucesso.'
        this.$emit('close')
        this.showSuccess = true
      } catch (error) {
        this.error = formatError(error.message)
        this.showError = true
      } finally {
        this.isLoading = false
      }
    },
    validate () {
      if (this.$refs.form.validate()) {
        this.e6 = 2;
      }
    },
    verifyPrice (e) {
      let priceMax = 0;
      if (this.test.type === 'technician') {
        switch (this.test.difficulty_level) {
          case 'basic': priceMax = 200; break;
          case 'intermediate': priceMax = 350; break;
          case 'advanced': priceMax = 500; break;
        }
      } else {
        switch (this.test.difficulty_level) {
          case 'basic': priceMax = 5000; break;
          case 'intermediate': priceMax = 12500; break;
          case 'advanced': priceMax = 25000; break;
        }
      }
      if (e > priceMax) {
        this.snackbar = true;
        this.test.price = priceMax;
      } else {
        this.snackbar = false;
      }
    },
  }
}
</script>