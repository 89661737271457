<template>
  <v-dialog v-model="dialog" persistent max-width="604">
    <preview-description
      :test="test"
      @close="closeDialog()"
      @initChat="initChat()"
      @start="setStart()"
      v-if="status === 'stop'"
    />
    <quiz
      :start="start"
      :test="test"
      @close="closeDialog()"
      v-else-if="status === 'start'"
    />
    <chatMessage
      v-if="showChat"
      @initChat="initChat()"
      :paramMessage="paramMessage"
      @close="close()"
    />
  </v-dialog>
</template>

<script>
import { API_URL } from "@/api";
import PreviewDescription from "./PreviewDescription.vue";
import Quiz from "./Quiz.vue";
import chatMessage from "./../../../../entity/modules/chat/components/chatMessage.vue";
import { mapGetters } from "vuex";
export default {
  name: "PreviewTest",
  components: { PreviewDescription, Quiz, chatMessage },
  props: {
    dialog: Boolean,
    test: Object,
  },
  data: () => ({
    apiUrl: API_URL,
    status: "stop",
    start: false,
    showChat: false,
    paramMessage: [],
  }),
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },
  created() {
    this.status = "stop";
    this.start = false;
  },
  methods: {
    closeDialog() {
      this.status = "stop";
      this.start = false;
      this.showChat = false;
      this.$emit("close");
    },
    setStart() {
      this.status = "start";
      this.start = true;
    },
    initChat() {
      this.paramMessage = {
        room: this.test.user.id + this.currentUser.id,
        testID: this.test.id,
        to: this.test.user.id,
      };
      this.showChat = !this.showChat;
      this.$emit("initChat");
    },
    close() {
      this.showChat = false;
    },
  },
  mounted() {
    // console.log("sala", this.test);
  },
};
</script>
