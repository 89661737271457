<template>
  <v-dialog
    v-model="processing"
    hide-overlay
    width="300"
    persistent
  >
    <v-card
      class="py-8"
    >
      <v-card-text>
        <div align="center">
          <p class="font-medium"> Por favor aguarde... </p>
        </div>
        
        <v-progress-linear
          indeterminate
          color="primary"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Progress',
  props: {
    processing: Boolean,
  },
}
</script>